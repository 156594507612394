import { Component, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { TableroService } from '../services/tablero.service';
import { MatTableDataSource } from '@angular/material/table';
import { UrlResolverService } from '../services/urlResolver.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

interface MotivoConsulta{
  categoria: string;
  subcategoria: string;
}

@Component({
  selector: 'app-pacientes-detalle-boleta',
  templateUrl: './pacientes-detalle-boleta.component.html',
  styleUrls: ['./pacientes-detalle-boleta.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class PacientesDetalleBoletaComponent implements OnInit {
  dataTriage: any;
  dataDet: any;
  cargando: boolean = true;
  errorGetDetalle: boolean = false;
  noExisteDetalle: boolean = false;
  displayedColumnasItems: string[] = ['matriculaEfector', 'nombreEfector', 'practica', 'detalle'];
  motivoConsulta: MotivoConsulta;

  constructor (
    private tableroService: TableroService,
    private urlResolverService: UrlResolverService
  ) {}

  ngOnInit(): void {
    this.dataTriage= this.tableroService.getCurrentTriage();
    this.getDetalleBoleta()
    this.getMotivoConsulta();
  }

  async getMotivoConsulta(){
    await this.urlResolverService.getHorusRestfullUrl().toPromise().then(async respGetUrl => {
      await this.tableroService.getMotivoConsulta(respGetUrl, this.dataTriage.idTriage).toPromise().then(async resp => {
        if (resp.ok) { 
          this.motivoConsulta = JSON.parse(resp.mensaje);
        }
      }, error => {
        console.log(error);
      })
    }, error => {
      console.log(error);
    })
  }

  async getDetalleBoleta(){
    this.cargando = true;
    this.errorGetDetalle = false;

    let jsonDetalle = {
      tipoDocumento: this.dataTriage.paciente.tipoDocumento,
      numeroDocumento: this.dataTriage.paciente.nroDocumento,
      fecha: this.dataTriage.fechaIngreso
    }

    await this.tableroService.getDetalleBoleta(jsonDetalle).toPromise().then(async resp => {
      if (resp.ok && resp.elementos.length > 0) {
        this.errorGetDetalle = false;
        this.noExisteDetalle = false;
        this.cargando = false;
        this.dataDet = resp.elementos;
        new MatTableDataSource(this.dataDet);
      } else {
        this.noExisteDetalle = true;
        this.cargando = false;
      }
    }, error => {
      console.log(error);
      this.errorGetDetalle = true;
      this.cargando = false;
    })
  }

}
